<template>
  <div class="common-padding">
    <div class="cart-content">
      <h2 class="common-h2">Shopping Cart</h2>
    <div  v-if="cartList.length > 0">
      <div class="cart-item" v-for="item in cartList" :key="item.id">
        <div class="cart-item-info">
          <div class="cart-item-img border-common">
            <img :src="item.image">
          </div>
          <div class="product-info-txt">
            <p><b>{{ item.name }}</b></p>
            <div>
              <span>{{ item.part_no }}</span> <span>x{{ item.quantity }}</span>
            </div>
          </div>
        </div>
        <div class="cart-item-delete" @click="deleteItem(item.id)">
          <img src="@/assets/img/close.png">
        </div>
      </div>
    </div>

    </div>

    <div class="block"></div>
    <div class="fixed-btn">

      <div class="cart-confirm"  @click="confirm">
        Confirm
      </div>

    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

</template>

<script>
import { getCart } from "@/network/main";
export default {
  name: "mobileCart",
  data() {
    return {
      cartList:[],
      insufficientStock: false,
      isPopupVisible: false,
      popupText: '',
    };
  },
  created() {
    //拿缓存数据
    const selectedIdsData = localStorage.getItem('selectedMobile');
    if (selectedIdsData) {
      this.selectedIds = JSON.parse(selectedIdsData);
    }
    //获取购物车
    this.getCart();
  },
  methods: {
    getCart() {
      const ids = Object.keys(this.selectedIds);
      const idString = ids.join(',');
      console.log(idString)
      getCart(idString).then(res => {
        if (res.data.code === 200) {
          const cartData = res.data.data;
          this.cartList = cartData.map(item => {
            const quantity = this.selectedIds[item.id];
            return {
              ...item,
              quantity: quantity
            };
          });
        }
      });
    },
    //确认购物车
    confirm() {
      this.insufficientStock = false; // 重置库存不足标识
      const insufficientItems = this.cartList.filter(item => item.quantity > item.stock);
      if (insufficientItems.length > 0) {
        // 存在库存不足的商品
        this.insufficientStock = true;
        // 获取库存不足的商品名称列表
        const insufficientItemNames = insufficientItems.map(item => item.name);
        const popupText = `The following parts are Unavailable :\n${insufficientItemNames.join('\n')}`;

        this.isPopupVisible = true;
        this.popupText = popupText;
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 3000);
      } else {
        // 库存充足，跳转页面
        this.$router.push('/mobileOrder');
      }
    },

    //删除
    deleteItem(id) {
      // 从缓存中删除对应的数据
      delete this.selectedIds[id];
      localStorage.setItem('selectedMobile', JSON.stringify(this.selectedIds));
      // 从cartList数组中删除对应的数据
      this.cartList = this.cartList.filter(item => item.id !== id);
    },
  }
}
</script>

<style scoped>
@import "~@/assets/css/mobile.css";
</style>